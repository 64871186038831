import React from 'react';

import { ButtonStyles } from '../../constants/ui';
import { IWordpressActualiteEntry } from '../../types/wordpress/actualite';
import { IWordpressCTA } from '../../types/wordpress/blocks';
import { tw } from '../../utils/tailwind';
import ActualiteEntry from '../ActualiteEntry/ActualiteEntry';
import { Grid } from '../Core';
import SectionWithTitle from '../SectionWithTitle';
import StyledLink from '../StyledLink';
import ThemedButton from '../ThemedButton';

interface IActualitesSectionProps {
  title?: string | undefined;
  actualites: readonly (IWordpressActualiteEntry | null)[];
  cta?: IWordpressCTA;
  seeMoreLabel?: string;
  horizontal?: boolean;
}

const ActualitesSection: FCWithClassName<IActualitesSectionProps> = (props) => {
  const { actualites, className, title, horizontal = false } = props;
  return (
    <SectionWithTitle
      heading={title}
      contentAlignment="center"
      className={tw('mb-36', className)}
    >
      <Grid
        className={`grid-cols-1 ${
          horizontal ? 'lg:grid-cols-1' : 'lg:grid-cols-3'
        } w-full space-y-16 justify-center items-center`}
      >
        {actualites?.map((actualite) => {
          return (
            <ActualiteEntry
              key={actualite.id}
              ctaLabel={props.seeMoreLabel}
              title={actualite.acfActualite.sectionHero.titre}
              url={actualite.uri}
              date={actualite.date}
              excerpt={actualite.excerpt}
              image={actualite.acfActualite.sectionHero.imageMobile}
              horizontal={horizontal}
            />
          );
        })}
      </Grid>
      {props.cta && (
        <StyledLink to={props.cta.internalLink.uri} className="mt-12">
          <ThemedButton variant={props.cta.style as ButtonStyles}>
            {props.cta.titre}
          </ThemedButton>
        </StyledLink>
      )}
    </SectionWithTitle>
  );
};

export default ActualitesSection;
