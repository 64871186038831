import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { IWordpressImage } from '../../types/wordpress/blocks';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { Box, Flex, Stack } from '../Core';
import StyledLink from '../StyledLink';
import Icon from '../SVG';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IActualiteEntryProps {
  ctaLabel: string;
  date: string;
  url: string;
  title: string;
  excerpt: string;
  image: IWordpressImage | null;
  horizontal?: boolean;
}

const ActualiteEntry: FCWithClassName<IActualiteEntryProps> = (props) => {
  const COLORS = ['feelgood', 'performance', 'primaryMain', 'accent.500'];
  const randomColor = COLORS[Math.floor(Math.random() * COLORS.length)];
  const image = props.image
    ? serializeImageForImageComponent({
        image: props.image,
      })
    : null;

  const contentExcerptWithoutLinkMore = props.excerpt.replace(
    /<p class="link-more">.*/,
    ''
  );
  return (
    <Flex
      direction={props.horizontal ? 'row' : 'column'}
      className={`${props.horizontal ? 'initial' : 'max-w-[300]'}`}
    >
      <Box className="hidden lg:block">
        {props.image ? (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="img"
          />
        ) : (
          <Flex
            justify="center"
            align="center"
            className="w-[300] h-[300]"
            style={{ background: randomColor }}
          >
            <Icon icon="u" className="h-48 text-white" />
          </Flex>
        )}
      </Box>
      <Stack
        className={`ml-0 ${props.horizontal ? 'lg:ml-4' : 'lg:ml-0'} space-y-4`}
      >
        <Text className="text-black-400">{props.date}</Text>
        <Text variant={TEXT_STYLES.H3} className="!text-5 !font-bold">
          {props.title}
        </Text>
        <Box
          dangerouslySetInnerHTML={{ __html: contentExcerptWithoutLinkMore }}
        />
        {/* We always display the view more button, because the WP abstract doesn't take into account shortcodes,
            therefore it can hide the fact that there is more to see */}
        <StyledLink
          variant="internalLink"
          to={props.url}
          className="w-fit !border-b-2 border-dotted border-b-text"
        >
          {props.ctaLabel}
        </StyledLink>
      </Stack>
    </Flex>
  );
};

export default ActualiteEntry;
