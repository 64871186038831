import { graphql, PageProps } from 'gatsby';
import React from 'react';

import ActualitesSection from '../../components/ActualitesSection';
import { Stack } from '../../components/Core';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';

type IPageActualites = PageProps<Queries.PageActualitesQuery, IPageContext>;

const PageActualites: FCWithClassName<IPageActualites> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuActualites' in data.wpPage.template ? data.wpPage.template.contenuActualites : null;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageTemplateData.actualites.sectionHero.imageMobile,
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap">
        <Hero
          backgroundImage={heroImageWithArtDirection}
          couleurTitre="#000"
          heading={pageTemplateData.actualites.sectionHero.titre}
          type={HERO_TYPE.STATIC}
        />
        <ActualitesSection
          actualites={data.allWpActualite.nodes}
          seeMoreLabel={
            data.allWpConfiguration.nodes[0].configuration.textes.voirPlus
          }
          horizontal
        />
      </Stack>
    </Layout>
  );
};

export default PageActualites;

export const query = graphql`
  query PageActualites($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      language {
        locale
        slug
      }
      translations {
        language {
          code
          locale
        }
        uri
      }
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      template {
        ...on WpTemplate_PageActualites {
          contenuActualites {
            actualites {
              sectionHero {
                titre
                imageMobile {
                  ...MediaItemFragment
                  localFile {
                    ...MobileHeroImage
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpActualite(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: date, order: DESC }
    ) {
      totalCount
      nodes {
        id
        slug
        uri
        acfActualite {
          sectionHero {
            titre
            imageMobile {
              ...MediaItemFragment
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    height: 300
                    
                    layout: FIXED
                  )
                }
                publicURL
              }
            }
          }
        }
        date(fromNow: true, locale: $currentLanguageSlug)
        excerpt
      }
    }
    allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          textes {
            voirPlus
          }
        }
      }
    }
  }
`;
